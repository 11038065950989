<template>
  <div :class="isMobile?'mobile-topbar':'topbar'">
    <div class="topbar-center" :style="styleStr">
      <div class="center-left">
        <div class="left-icon">
          <a href="/"><img src="@/assets/images/logo.png" alt="MASS"/></a>
        </div>
        <div class="left-btn" @click="clickHome" :class="{ active: currentActive === 'home' }">
          {{ $t('topData.home.name') }}
        </div>
        <div class="left-btn" v-if="showCreator" @click="clickBtn($t('topData.creator.path'))" :class="{ active: currentActive === 'creator' }">
          {{ $t('topData.creator.name') }}
        </div>
      </div>
      <div class="center-right">
        <div class="right-login" v-if="showTutorial" @click="clickBtn($t('topData.editorTutorial.path'))">
          {{ $t('topData.editorTutorial.name')}}
        </div>
        <div v-if="showDownload" @click="clickDownload">
          <div class="right-down" 
            @mousedown="mousedown"
            @mousemove="mousemove"
            @mouseup="mouseup"
            @touchmove="mousemove"
            @touchstart="mousedown"
            @touchend="mouseup"
            ref="flbutton"
            v-if="isMobile">
          </div>
          <div class="right-login" v-else>
            {{ (currentActive==='creator' || currentActive==='userCreate' || currentActive==='articleDetail' ) ? $t('topData.downloadEditor.name'): $t('topData.downloadApp.name') }}
          </div>
        </div>
        <div class="right-gmes" v-if="showGems" @click="onClickGems($t('topData.buyGems.path'))">
          <img src="@/assets/images/icon_caizuan.png" :alt="$t('topData.buyGems.name')"/>
          <span class="name">{{ $t('topData.buyGems.name') }}</span>
        </div>
        <div v-if="showLogin" @click="clickLogin">
          <div class="right-menu" v-if="userInfo.user_id">
            <img  :src="userInfo.avatar" />
          </div>
          <div class="right-login" v-else>{{ $t('topData.login.name') }}</div>
        </div>
        <div class="right-menu" v-if="showSetting">
          <div @click="showMenu" v-if="isMobile">
            <img src="@/assets/images/icon_menu.png" :alt="$t('topData.setting.name')"/>
          </div>
          <div @mouseover="mouseover" @click="showMenu" v-else>
            <img src="@/assets/images/icon_menu_pc.png"/>
          </div>
        </div>
      </div>
    </div>

    <div class="topbar-menubar" 
        @mouseover="mouseover"
        @mouseleave="mouseleave"
      v-if="isShowMenu">
      <div class="padding-top50" v-if="isMobile"></div>
      <div class="menubar-user" v-if="userInfo.user_id">
        <img :src="userInfo.avatar" class="icon"/>
        <a href="/order" class="name">{{ userInfo.nickname }}</a>
        <img  src="@/assets/images/icon_close.png" class="close" @click="closeMenubar" v-if="isMobile"/>
      </div>

      <div class="menubar-user" v-if="!userInfo.user_id && isMobile">
        <img src="@/assets/images/icon_user.png" class="icon"/>
        <a :href="$t('topData.login.path')" class="name">
          {{ $t('topData.login.name') }}
          <img src="@/assets/images/icon_right.png" class="right"/>
        </a>
        <img  src="@/assets/images/icon_close.png" class="close" @click="closeMenubar" v-if="isMobile"/>
      </div>

      <div class="menubar-gems">
        <img src="@/assets/images/icon_caizuan.png" class="icon"/>
        <a @click="onClickGems($t('topData.buyGems.path'))">{{ $t('topData.buyGems.name') }}</a>
      </div>
      <div class="menubar-items">
        <div class="items" v-for="(items,index) in $t('menuList')" :key="index">
          <li class="name">{{ items.name }}</li>
          <ul class="items-sub">
            <li v-for="(item,key) in items.list" :key="key" @click="goItemPath(item.path)" :class="{ active: currentActive === item.pathName }">
             {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="menubar-logout" @click="goLogout" v-if="userInfo.user_id">{{ $t('loginData.logout') }}</div>
      <!-- <div class="menubar-logout" @click="goLogin" v-else>测试登录</div> -->
      <div class="menubar-language" v-if="isMobile">
        <div class="language" v-if="languageData.length > 1">
          <label @click="showLanguage">{{ languageName }}</label>
          <ul v-if="isShowLanguage">
            <li v-for="item in languageData" :key="item.language_id" @click="changeLanguage(item.language_id, item.language_name)" :class="(item.language_name === languageName)?'select':''">{{ item.language_name }}</li>
          </ul>
        </div>
      </div>
      <div class="padding-top24"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'topBar',
  props: {
    showDownload: {
      type: Boolean,
      default: true
    },
    showCreator: {
      type: Boolean,
      default: true
    },
    showGems: {
      type: Boolean,
      default: true
    },
    showLogin: {
      type: Boolean,
      default: true
    },
    showSetting: {
      type: Boolean,
      default: true
    },
    showTutorial: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      languageName: '',
      isShowLanguage: false,
      currentActive: '',
      styleStr: 'min-height: 80px;',
      isShowMenu: false,
      style: {
        top: 0,
        mouseDownflag: false, // 鼠标点击状态
        position: {
          y: 0,
        },
        ny: "",
        dy: "",
        yPum: "",
        movb: 1,//区分拖拽还是点击
        menuPosition: 'right',
      }
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'userInfo', 'userToken', 'language', 'languageData', ]),
  },
  created () {
    // if (this.isMobile) {
    //   this.styleStr = 'min-height: 60px;'
    // }
    var query = window.location.pathname
    var vars = query.split('/')
    if (vars.length>1) {
      if (vars[1].length === 0) {
        this.currentActive = 'home'
      }else{
        this.currentActive = vars[1]
      }
    }
    this.getLanguageName()
  },
  methods: {
    ...mapActions(['UserOut', 'TestLogin','SetLanguage', 'downloadApp']),
    showMenu () {
      if (this.isShowMenu) {
        this.isShowMenu = false
      } else {
        this.isShowMenu = true
      }
    },
    closeMenubar () {
      this.isShowMenu = false
    },
    changeLanguage (value, name) {
      this.languageName = name
      this.SetLanguage(value)
      window.location = window.location.href
    },
    clickBtn( url) {
      window.location = url
    },
    clickHome (){
      this.$router.push({ name: 'home' })
    },
    clickDownload () {
      console.log('this.currentActive==',this.currentActive)
      // let mobile = this.isMobile ? 'mobile' : 'pc'
      let mobile = 'mobile'
      if (this.currentActive === 'creator' || this.currentActive === 'userCreate' || this.currentActive==='articleDetail') {
        mobile = 'pc'
      }
      this.downloadApp(mobile)
    },
    onClickGems (url) {
      if (this.userInfo.user_id) {
        if (url) {
          url = url + '?user-id='+this.userInfo.user_id
          let a = document.createElement('a');
          a.setAttribute('href', url);
          // a.setAttribute('target', '_blank');
          a.click();
        } else {
          this.showError('Url is  error ！')
          return false;
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    clickLogin () {
      if (this.userInfo.user_id) {
        this.$router.push({ name: 'order' })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    goItemPath (url) {
      window.location.href = url
    },
    goLogout () {
      this.UserOut().then((res) => {
        window.location.href = ('/')
      })
    },
    goLogin () {
      this.TestLogin().then((res) => {
        window.location.href = ('/')
      })
    },
    //鼠标拖拽移动
    mouseover() {
      this.isShowMenu = true
      console.log('mouseover===')
    },
    mouseleave() {
      this.isShowMenu = false
      console.log('mouseleave===')
    },
    showLanguage() {
      if (!this.isShowLanguage) {
        this.isShowLanguage = true
      } else {
        this.isShowLanguage = false
      }
    },
    changeLanguage (value, name) {
      this.languageName = name
      this.isShowReason = false
      this.SetLanguage(value)
      window.location = window.location.href
    },
    getLanguageName () {
      if (this.languageName==='') {
        this.languageData.forEach(element => {
          if (this.language === element.language_id) {
            this.languageName = element.language_name
          }
        });
      }
    },
    //鼠标摁下
    mousedown() {
      this.style.mouseDownflag = true;
      /*此处判断pc 或移动端得到event 事件*/
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      //鼠标点击面向页面的x坐标y坐标
      this.style.position.y = touch.clientY;
      //鼠标y坐标－拖拽按钮y坐标得到鼠标距离拖拽按钮的间距
      this.style.dy = this.$refs.flbutton.offsetTop;
    },
    //鼠标拖拽移动
    mousemove() {
      if (this.style.mouseDownflag) {
        this.movb = 2;
        /*此处判断得到event事件*/
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        //鼠标坐标－鼠标与拖拽按钮的间距坐标得到拖拽按钮的左上角x轴y轴坐标
        this.style.ny = touch.clientY - this.style.position.y;
        this.style.yPum = this.style.dy + this.style.ny;
        let height = window.innerHeight - this.$refs.flbutton.offsetHeight; //屏幕高度减去自身控件高度
        /* 此处判断
          拖拽按钮 如果超出屏幕宽高或者小于
          设置屏幕最大 x=全局容器x y=全局容器y
          否则 设置 为 x=0 y=0
        */

        this.style.yPum < 0 && (this.style.yPum = 0);
        this.style.yPum > height && (this.style.yPum = height);
        // 计算后坐标  设置 按钮位置
        this.style.menuPosition = 'right'
        this.$refs.flbutton.style.top = this.style.yPum + "px";
        this.style.top = this.style.yPum;
      }
    },
    //鼠标抬起
    mouseup() {
      this.style.mouseDownflag = false;
    }
  }
}
</script>
